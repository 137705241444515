<template>
  <div @click.stop="">
    <!-- 버튼 그룹 -->
    <div class="d-flex py-4 px-4 px-sm-6">
      <!-- 감정표현 -->
      <FeelingBtn v-bind="$props" v-on="$listeners" />
      <v-spacer></v-spacer>
      <!-- 댓글 -->
      <ReplyBtn :count="post.replyCount" v-bind="$props" v-on="$listeners" />
      <v-divider vertical class="my-2"></v-divider>
      <!-- 읽음 -->
      <ReadBtn :count="post.readCount" v-bind="$props" v-on="$listeners" />
    </div>
    <!-- 리스트 -->
    <div @click.stop="">
      <!-- 감정 표현 다이얼로그 -->
      <FeelingDialog
        v-if="postLayout.showFeelList"
        v-bind="$props"
        v-on="$listeners"
      />
      <!-- 읽음 다이얼로그 -->
      <ReadDialog
        v-if="postLayout.showReadListBox"
        v-bind="$props"
        v-on="$listeners"
      />
      <!-- 댓글 리스트 -->
      <ReplyBox v-if="postLayout.showReplyBox" v-bind="$props" />
    </div>
  </div>
</template>

<script>
import FeelingBtn from "@/board/views/components/main/post/footer/feeling/FeelingBtn";
import ReplyBtn from "@/board/views/components/main/post/footer/reply/ReplyBtn";
import ReplyBox from "@/board/views/components/main/post/footer/reply/ReplyBox";
import ReadBtn from "@/board/views/components/main/post/footer/read/ReadBtn";
import FeelingDialog from "@/board/views/components/dialog/sub/FeelingDialog";
import ReadDialog from "@/board/views/components/dialog/sub/ReadDialog";

export default {
  components: {
    FeelingBtn,
    ReplyBtn,
    ReplyBox,
    ReadBtn,
    ReadDialog,
    FeelingDialog
  },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시물 타입"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  data() {
    return {};
  }
};
</script>
