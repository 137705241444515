<template>
  <NoData v-if="showLoading"></NoData>
  <Post v-else :post="post" :type="'dialog'" />
</template>
<script>
import Post from "@/board/views/components/main/post";
import NoData from "@/board/views/components/main/post/NoData";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { Post, NoData },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    }
  },
  data: () => ({
    showLoading: true
  }),
  async mounted() {
    this.showLoading = true;

    // 게시글 불러오기
    await this.loadPost(this.postId);
    // 댓글 불러오기
    if (this.replyId > 0) {
      await this.RESET_REPLY(this.post.id);
      await this.loadReplyListById({
        postId: this.postId,
        replyId: this.replyId
      });
    }

    this.showLoading = false;
  },
  computed: {
    ...mapGetters("homeDialog", { homeParams: "getParams" }),
    ...mapGetters("boardDialog", { boardParams: "getParams" }),
    ...mapGetters("boardPost", ["getPostById"]),
    post() {
      return this.getPostById(
        this.$route.name == "home"
          ? this.homeParams.postId
          : this.boardParams.postId
      );
    },
    postId() {
      return this.$route.name == "home"
        ? this.homeParams.postId
        : this.boardParams.postId;
    },
    replyId() {
      return this.boardParams?.replyId ?? 0;
    }
  },
  methods: {
    ...mapActions("boardPost", ["loadPost"]),
    ...mapActions("boardReply", ["loadReplyListById"]),
    ...mapMutations("boardReply", ["RESET_REPLY"])
  }
};
</script>

<style lang="scss" scoped></style>
