import { render, staticRenderFns } from "./PostHeader.vue?vue&type=template&id=2303cfeb&scoped=true&"
import script from "./PostHeader.vue?vue&type=script&lang=js&"
export * from "./PostHeader.vue?vue&type=script&lang=js&"
import style0 from "./PostHeader.vue?vue&type=style&index=0&id=2303cfeb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2303cfeb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCardTitle,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VTooltip})
