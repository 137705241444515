<template>
  <v-dialog width="400" v-model="isShow" class="cr-read-dialog">
    <v-card :loading="loading">
      <v-btn icon small absolute top right @click="setIsShow(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="pl-4 pt-3 pb-0 text-h6 font-weight-bold">
        읽음 여부
      </v-card-title>
      <!-- 탭 헤더 -->
      <v-tabs v-model="tab">
        <v-tab :href="`#${item.tab}`" v-for="item in tabList" :key="item.tab">
          <div class="d-flex align-end">
            <span class="mr-1">{{ item.name }}</span>
            <span v-if="item.count > 0">{{ item.count }}</span>
          </div>
        </v-tab>
      </v-tabs>
      <!-- 탭 내용 -->
      <div style="min-height:250px; max-height:450px; overflow:auto;">
        <!-- 읽은 사용자 목록 -->
        <div v-if="loading" class="d-flex align-center justify-center py-6">
          <span class="grey--text text-caption">불러오는중</span>
        </div>
        <div v-else-if="tab === 'tab-read'">
          <v-list v-if="readList.length > 0">
            <UserItem
              v-for="item in readList"
              :key="item.userId"
              :userId="item.userId"
              :userName="item.userName"
              :userEmail="item.userEmail"
              :timeMillis="item.readTimeMillis"
              class="py-1"
            />
          </v-list>
          <div v-else class="d-flex align-center justify-center py-6">
            <span class="grey--text text-caption">데이터가 없습니다.</span>
          </div>
        </div>
        <!-- 안읽은 사용자 목록 -->
        <!-- TODO : 추후 알림 기능 추가시 안읽은 사용자에게 알림 액션 등 구현 -->
        <div v-else>
          <div v-if="loading" class="d-flex align-center justify-center py-6">
            <span class="grey--text text-caption">불러오는중</span>
          </div>
          <v-list v-else-if="unReadList.length > 0">
            <UserItem
              v-for="item in unReadList"
              :key="item.userId"
              class="py-1"
              :userId="item.userId"
              :userName="item.userName"
              :userEmail="item.userEmail"
            />
          </v-list>
          <div v-else class="d-flex align-center justify-center py-6">
            <span class="grey--text text-caption">데이터가 없습니다.</span>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
<script>
import UserItem from "@/board/views/components/common/item/UserItem";
import { getUnRead } from "@/board/api/board.api";
import { mapActions } from "vuex";
import i18n from "@/_locales";
import moment from "moment";

export default {
  components: { UserItem },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  data: () => ({
    tab: "tab-read",
    loading: false,
    unRead: {
      list: []
    }
  }),
  watch: {
    tab(tab) {
      if (tab === "tab-unread") {
        this.loadUnReadList();
      }
    }
  },
  async mounted() {
    // 리스트 생성시 감정표현 목록 업데이트
    this.loading = true;
    await this.getRead(this.post.id);
    this.loading = false;
  },
  computed: {
    // 다이얼로그 표시여부
    isShow: {
      get: function() {
        return this.postLayout.showReadListBox;
      },
      set: function(val) {
        this.setIsShow(val);
      }
    },
    // 표시할 탭 리스트
    tabList() {
      const tabs = [
        {
          tab: "tab-read",
          type: "READ",
          name: "읽음",
          count: this.readList.length
        },
        {
          tab: "tab-unread",
          type: "UNREAD",
          name: "안읽음",
          count: this.unReadList.length
        }
      ];
      return tabs;
    },
    // 읽음 목록
    readList() {
      return this.post?.readList ?? [];
    },
    // 안읽음 목록
    unReadList() {
      return this.unRead?.list ?? [];
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("boardPost", ["getRead"]),
    avatar(userName) {
      return userName.slice(0, 1).toUpperCase();
    },
    // 날짜 포맷
    convertDateFromMillis(millis, format = "YYYY.MM.DD (dd) HH:mm") {
      return moment(millis).format(format);
    },
    // 안읽은 사용자 목록 조회
    async loadUnReadList() {
      this.loading = true;

      const { status, data } = await getUnRead(this.post.id);
      if (status === 200) {
        this.unRead.list = data.map(user => ({
          userId: user.id,
          userName: user.accountName,
          userEmail: user.username
        }));
      } else {
        this.openSnackbar({
          message: i18n.t("board.19"),
          type: "ERROR"
        });
      }

      this.loading = false;
    },
    setIsShow(isShow) {
      this.$emit("onLayoutChange", {
        showReadListBox: isShow
      });
    }
  }
};
</script>
