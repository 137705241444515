<template>
  <v-card-title class="cr_header_wrapper pa-0 px-4 px-sm-6">
    <!-- 헤더 아이콘 -->
    <div class="cr_header_icon_wrapper" v-if="type != 'dialog'">
      <!-- 중요 -->
      <v-icon v-if="post.isImportant" color="primary" size="24">
        mdi-sticker
      </v-icon>
      <!-- 공지사항 -->
      <v-icon v-if="post.isNotice" color="primary" size="24">
        mdi-pin
      </v-icon>
    </div>

    <!-- 유저 정보 -->
    <v-list-item class="px-0 mt-2 cr-user-info">
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-1">
          <div class="d-flex align-center">
            <!-- 프로필 아이콘-->
            <div>
              <div class="cr-avatar mr-2">{{ avatar }}</div>
            </div>
            <!-- 작성자 게시판 정보 -->
            <div class="d-flex flex-column justify-center">
              <div
                class="d-flex align-center font-weight-bold"
                style="padding-top: 2px;"
              >
                <!-- 작성자 -->
                <UserName
                  :userId="post.userId"
                  :userName="post.userName"
                  :userEmail="post.userEmail"
                />
                <v-icon class="mx-1" size="18">
                  mdi-chevron-right
                </v-icon>
                <!-- 게시판 이름 -->
                <v-tooltip :disabled="showBoardName" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      class="d-flex align-center grey--text text--darken-1 board-info"
                    >
                      <span class="mr-1">{{ boardName }}</span>
                      <!-- 비공개 게시판 아이콘 -->
                      <v-icon
                        v-if="boardType === 'MEMBER'"
                        color="grey"
                        size="17"
                        style="margin-bottom: 2px;"
                      >
                        mdi-lock
                      </v-icon>
                      <!-- 갱신 버튼 -->
                      <v-btn
                        v-if="isUnknownBoard"
                        icon
                        small
                        @click.stop="loadBoards"
                      >
                        <v-icon>
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <!-- 숨김 또는 새로 추가된 게시판입니다. -->
                  <div v-html="$t('board.150')"></div>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center font-weight-bold"
                style="padding-top: 2px;"
              >
                <!-- 날짜 -->
                <span
                  class="mr-1 text-caption grey--text text--darken-2"
                  style="line-height: 1rem; padding-top:2px;"
                >
                  {{ convertDateFromMillis(post.createdTimeMillis) }}
                </span>

                <span v-if="post.updateUserId > 0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="d-block" small v-bind="attrs" v-on="on">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{ this.updateUserInfo }}</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="showMenu" class="ma-0">
        <v-btn
          class="cr-tutorial-board-post-more"
          icon
          @click.stop="e => openPositioningMenu(e)"
        >
          <v-icon color="grey lighten-1">
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card-title>
</template>

<style lang="scss" scoped>
.cr_header_wrapper {
  .cr_header_icon_wrapper {
    position: absolute;
    left: 18px;
    top: -18px;
  }
}
.cr-user-info {
  .cr-avatar {
    font-size: 1.2rem;
    text-align: center;
    border-radius: 50%;
    line-height: 43px;
    width: 43px;
    height: 43px;
    background: #90a4ae;
    color: white;
  }
  .board-info {
    font-weight: 400;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import uploadType from "@/board/constant/uploadType";
import UserName from "@/board/views/components/common/item/UserName";
import moment from "moment";
import i18n from "@/_locales";

export default {
  components: { UserName },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시물 타입"
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("board", ["getBoardById"]),
    ...mapGetters("boardRoute", ["getRouteListInfo"]),
    showBoardName() {
      return this.getBoardById(this.post.boardId)?.name ? true : false ?? false;
    },
    isUnknownBoard() {
      return this.getBoardById(this.post.boardId) ? false : true;
    },
    boardName() {
      return this.getBoardById(this.post.boardId)?.name ?? i18n.t("board.148");
    },
    boardType() {
      return this.getBoardById(this.post.boardId)?.memberType ?? "ANY";
    },
    showMenu() {
      if (this.$route.name == "home" || this.isDeleted) return false;
      return true;
    },
    avatar() {
      return this.post.userName.slice(0, 1).toUpperCase();
    },
    isDeleted() {
      const board = this.getBoardById(this.post.boardId);
      const boardDeleted = board?.isDeleted ?? false;
      const boardTrashType = board?.boardType === "TRASH" ?? false;

      return this.post.isDeleted || boardDeleted || boardTrashType;
    },
    // 쓰기권한 (본인 게시글 or 관리자) 여부
    hasWritePrivileage() {
      const { boardId, userId } = this.post;
      if (!boardId || !userId) return false;

      const targetBoard = this.getBoardById(boardId);
      if (!targetBoard) return false;

      switch (targetBoard.userPrivilege) {
        case "ADMIN":
          return true;
        case "READ_WRITE":
        case "READ":
          return userId == this.getUserInfo.id;
      }
      return false;
    },
    updateUserInfo() {
      return `${i18n.t("board.수정시간")} : ${this.post.updateUserName} <${
        this.post.updateUserEmail
      }> / ${moment(this.post.updatedTimeMillis).format(
        "YYYY.MM.DD (dd) HH:mm"
      )}`;
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("boardPost", [
      "deletePost",
      "deletePostView",
      "getFileList",
      "updateIsNotice"
    ]),
    ...mapActions("board", ["loadBoards"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    // 날짜 포맷
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    // 컨텍스트 메뉴
    openPositioningMenu(e) {
      e.preventDefault();
      this.closePositioningMenu();
      const itemList = [
        {
          // 게시물 수정
          label: i18n.t("board.92"),
          hideItem: !this.hasWritePrivileage,
          func: () => this.openEditPostDialog()
        },
        {
          // 게시물 삭제
          label: i18n.t("board.93"),
          hideItem: !this.hasWritePrivileage,
          func: () => {
            this.openConfirmDialog("", i18n.t("board.94"), () => {
              if (this.type == "list") {
                this.deletePost(this.post.id);
              } else {
                this.deletePostView(this.post.id);
              }
            });
          }
        },
        {
          // 공지사항 등록/제거
          label: this.post.isNotice ? i18n.t("board.151") : i18n.t("board.152"),
          hideItem: !this.hasWritePrivileage,
          func: () => {
            this.openConfirmDialog(
              "",
              this.post.isNotice ? i18n.t("board.153") : i18n.t("board.154"),
              () => {
                this.updateIsNotice({
                  postId: this.post.id,
                  isNotice: !this.post.isNotice
                });
              }
            );
          }
        },
        {
          // URL 복사
          label: "URL 복사",
          func: async () => {
            await this.copyUrl();
            this.openSnackbar({
              message: i18n.t("common.39"),
              type: "SUCCESS"
            });
          }
        }
      ];
      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList
      });
    },
    // 게시글 수정 다이얼로그
    async openEditPostDialog() {
      // 첨부파일 로딩
      if (
        this.post.fileCount > 0 &&
        this.post.fileCount != this.post.files.length
      ) {
        await this.getFileList({
          postId: this.post.id,
          uploadType: uploadType.POST
        });
      }
      // 수정 다이얼로그 호출
      this.SET_DIALOG({
        title: i18n.t("board.92"),
        type: "editPost",
        params: {
          post: {
            ...this.post,
            content: this.replaceAccessToken(this.post.content)
          }
        }
      });
    },
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    replaceAccessToken(content) {
      const regex = /@ACCESS_TOKEN@/g;
      return content.replace(regex, localStorage.getItem("access_token"));
    },
    // 게시글 상세보기 URL 복사
    copyUrl() {
      const { boardId, id: postId } = this.post;
      const actionObj = { boardId };
      const origin = window.location.origin;
      let url = `${origin}/#/board/view/${postId}/${JSON.stringify(actionObj)}`;
      url = encodeURI(url);

      // Element가 body 내에 있어야 select() 가능
      const inputEl = document.createElement("input");
      inputEl.setAttribute("value", url);
      document.body.appendChild(inputEl);

      inputEl.select();
      document.execCommand("copy");
      document.body.removeChild(inputEl);
    }
  }
};
</script>
