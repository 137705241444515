<template>
  <div class="cr-board-content">
    <div
      class="cr-content-wrapper"
      :class="postLayout.isUnfold ? 'unfold' : ''"
    >
      <!-- 태그 -->
      <TagList v-if="post.tags.length > 0" :tags="post.tags" class="py-1" />
      <!-- 제목 -->
      <div class="cr-title-wrapper d-flex align-center">
        <div class="cr-title text-subtitle-1 font-weight-bold black--text py-2">
          {{ post.title }}
        </div>
      </div>
      <!-- 본문 -->
      <div
        ref="content"
        class="cr-content py-2"
        :class="isOverFlowed && type == 'dialog' ? 'cr-hrizontal-scroll' : ''"
        v-html="convertedContent"
        @click="contentClick"
      />
      <!-- 본문 크게 보기 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showExpandBtn"
            icon
            large
            tile
            outlined
            style="border-radius:4px;"
            class="cr-btn-expand"
            color="black"
            v-on="on"
            v-bind="attrs"
            @click="e => openExpand(e)"
          >
            <v-icon>
              mdi-arrow-expand-all
            </v-icon>
          </v-btn>
        </template>
        <span>크게 보기</span>
      </v-tooltip>
      <!-- 본문 하단 블러 -->
      <div
        v-show="!postLayout.isUnfold && !showExpandBtn"
        class="cr_bottom_bg"
      />
    </div>
    <!-- 링크공유 -->
    <div
      v-if="post.sharedLink"
      class="mt-3 link-preview"
      :class="$vuetify.breakpoint.name == 'xs' ? 'mobile' : ''"
    >
      <div class="d-flex align-center">
        <v-icon class="d-block ml-2">mdi-link</v-icon>
        <a
          v-text="post.sharedLink.link"
          class="d-block ml-2 mr-2 cr-link"
          @click.stop="openLink"
        ></a>
      </div>
      <LinkPreview :sharedLink="post.sharedLink" />
    </div>
    <!-- 첨부파일 -->
    <div v-show="hasFiles" class="cr_files_wrapper mt-5">
      <div class="file_title d-flex align-center mt-5">
        <v-icon small class="mr-1" @click.stop="showFileList = !showFileList">
          {{ showFileList ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
        <span class="mr-1">
          <v-icon size="16">
            mdi-paperclip
          </v-icon>
          {{ $t("board.첨부파일") }}
        </span>
        <span
          class="file_total_cnt"
          v-html="$t('board.63', { value: post.fileCount })"
        >
        </span>
      </div>
      <FileList
        v-if="showFileList"
        v-bind="$props"
        :showFileList.sync="showFileList"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-content-wrapper {
  max-width: 100%;
  max-height: 300px;
  overflow: hidden;
  position: relative;

  &.unfold {
    max-height: none;
    .cr_bottom_bg {
      background: none;
    }
    .cr-title,
    .cr-content {
      cursor: text;
      color: #000;
    }
  }

  .cr-title {
    font-size: 1.1rem !important;
    user-select: text;
    cursor: pointer;
  }
  .cr-content {
    // overflow-x: scroll;
    user-select: text;
    color: #000;
    cursor: pointer;

    ::v-deep p {
      margin: 0px !important;
      padding: 0px !important;
    }
    ::v-deep img {
      max-width: 100%;
    }
  }
  .cr-hrizontal-scroll {
    overflow-x: scroll;
  }
  .cr-btn-expand {
    background: white;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .cr_bottom_bg {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
}

.link-preview {
  margin: 0px -24px;
  &.mobile {
    margin: 0px -16px;
  }

  .cr-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}

.cr_files_wrapper {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  .file_title {
    .file_total_cnt {
      margin-right: 2px;
    }
  }
}
</style>

<script>
import FileList from "./file/FileList.vue";
import LinkPreview from "@/board/views/components/main/post/content/link/LinkPreview";
import TagList from "@/board/views/components/common/TagList.vue";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { mapMutations } from "vuex";

export default {
  components: { FileList, LinkPreview, TagList },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시물 타입"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  data: () => ({
    isOverFlowed: false,
    isLoading: false,
    showFileList: false,
    content: ""
  }),
  mounted() {
    // 본문 영역을 초과하는 게시글일 경우
    const { scrollWidth, offsetWidth } = this.$refs.content;
    if (scrollWidth > offsetWidth) this.isOverFlowed = true;
    if (this.type == "dialog" && this.isOverFlowed)
      this.SET_DIALOG({ type: "viewPostExpand" });
  },
  watch: {
    postLayout(n, p) {
      // 본문 펼칠때 첨부파일 목록 같이 열기
      if (n?.isUnfold === p?.isUnfold || !this.hasFiles) return;
      this.showFileList = n.isUnfold;
    }
  },
  computed: {
    hasFiles() {
      return this.post.fileCount > 0;
    },
    convertedContent() {
      const regex = /@ACCESS_TOKEN@/g;
      let content = this.post.content;
      return content.replace(regex, localStorage.getItem("access_token"));
    },
    showExpandBtn() {
      return (
        (this.post.type == "MAIL" || this.isOverFlowed) && this.type != "dialog"
      );
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    // 파일 사이즈 포맷
    byteCalculation(size) {
      return byteCalculation(size);
    },

    /** 상태 값 */
    fileIcons(ext) {
      return fileIcons[ext] || fileIcons["file"];
    },
    fileIconColors(ext) {
      return fileIconColors[ext] || fileIconColors["file"];
    },

    // 파일 확장자
    fileExt(item) {
      const index = item.name.lastIndexOf(".");
      return item.name.substring(index + 1, item.name.length).toLowerCase();
    },
    // 본문 링크 <a> 이벤트 처리
    contentClick(e) {
      // 클릭한 요소가 A 태그인지 확인
      const target = e.target;
      const aTag = target.tagName === "a" ? target : target.closest("a");
      if (aTag) {
        e.stopPropagation();
        aTag.setAttribute("target", "_blank");
      }
    },
    // 링크 새창으로 열기
    openLink() {
      window.open(this.post.sharedLink.link, "_blank");
    },
    // 본문 크게 보기
    openExpand(e) {
      e.stopPropagation();
      this.SET_DIALOG({ type: "viewPost", params: { postId: this.post.id } });
    }
  }
};
</script>
