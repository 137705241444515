<template>
  <v-dialog width="550" v-model="isShow" class="cr-feeling-dialog">
    <v-card :loading="loading">
      <v-btn icon small absolute top right @click="setIsShow(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="pl-4 pt-3 pb-0 text-h6 font-weight-bold">
        감정표현 목록
      </v-card-title>
      <v-tabs v-model="tab" show-arrows>
        <v-tab :href="`#${item.tab}`" v-for="item in tabList" :key="item.tab">
          <span
            v-if="item.type != 'ALL'"
            class="cr-feel-icon ma-0 mr-1"
            v-html="feltType(item.type).svg"
          >
          </span>
          <span style="margin-right:2px">{{ item.name }}</span>
          <span v-if="item.list.length > 0">{{ item.list.length }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="tabItem in tabList"
          :key="tabItem.tab"
          :value="tabItem.tab"
          style="min-height:250px; max-height:450px; overflow:auto;"
        >
          <div v-if="loading" class="d-flex align-center justify-center py-6">
            <span class="grey--text text-caption">불러오는중</span>
          </div>
          <v-list v-else>
            <v-list-item
              v-for="item in tabItem.list"
              :key="item.id"
              class="py-1"
            >
              <!-- All 탭에서는 표시한 감정표현 뱃지로 보여줌 -->
              <v-badge
                v-if="tabItem.tab === 'tab-all'"
                color="white"
                bottom
                overlap
              >
                <template v-slot:badge>
                  <div
                    v-html="feltType(item.feelType).svg"
                    class="cr-feel-icon"
                  ></div>
                </template>
                <v-list-item-avatar class="cr-avatar ma-0"
                  >{{ avatar(item.userName) }}
                </v-list-item-avatar>
              </v-badge>
              <!-- 그 외에는 Badge 제거 -->
              <v-list-item-avatar v-else class="cr-avatar ma-0"
                >{{ avatar(item.userName) }}
              </v-list-item-avatar>
              <!-- 이름 -->
              <div class="d-flex align-center ml-4 mr-auto">
                <UserName
                  :userId="item.userId"
                  :userName="item.userName"
                  :userEmail="item.userEmail"
                  class="text-subtitle-1"
                />
                <div v-if="tabItem.tab === 'tab-all'">
                  <span>님이 </span>
                  <span
                    v-text="$t(`board.${feltType(item.feelType).title}`)"
                  ></span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.cr-avatar {
  line-height: 35px;
  width: 35px;
  height: 35px;
  background: #90a4ae;
  color: white;
}
.cr-feel-icon {
  width: 20px;
  height: 20px;
  margin-left: -4px;
  margin-top: -4px;
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import { feelTypeList } from "@/board/constant/boardConstraint.js";
import UserName from "@/board/views/components/common/item/UserName";

export default {
  components: { UserName },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  data: () => ({
    tab: null,
    feelTypeList,
    loading: false
  }),
  async mounted() {
    // 리스트 생성시 감정표현 목록 업데이트
    this.loading = true;
    await this.getFeeling(this.post.id);
    this.loading = false;
  },
  computed: {
    // 다이얼로그 표시여부
    isShow: {
      get: function() {
        return this.postLayout.showFeelList;
      },
      set: function(val) {
        this.setIsShow(val);
      }
    },
    // 감정표현 리스트
    feelingList() {
      return this.post.feelList ?? [];
    },
    likedList() {
      return this.feelingList.filter(f => f.feelType === "LIKED");
    },
    congratulationsList() {
      return this.feelingList.filter(f => f.feelType === "CONGRATULATIONS");
    },
    coolList() {
      return this.feelingList.filter(f => f.feelType === "COOL");
    },
    cheerList() {
      return this.feelingList.filter(f => f.feelType === "CHEER");
    },
    // 표시할 탭 리스트
    tabList() {
      const tabs = [
        {
          tab: "tab-all",
          type: "ALL",
          name: "전체",
          list: this.feelingList
        }
      ];
      if (this.likedList.length > 0) {
        tabs.push({
          tab: "tab-liked",
          type: "LIKED",
          name: "좋아요",
          list: this.likedList
        });
      }
      if (this.congratulationsList.length > 0) {
        tabs.push({
          tab: "tab-congratulations",
          type: "CONGRATULATIONS",
          name: "축하해요",
          list: this.congratulationsList
        });
      }
      if (this.coolList.length > 0) {
        tabs.push({
          tab: "tab-cool",
          type: "COOL",
          name: "멋져요",
          list: this.coolList
        });
      }
      if (this.cheerList.length > 0) {
        tabs.push({
          tab: "tab-cheer",
          type: "CHEER",
          name: "힘내요",
          list: this.cheerList
        });
      }
      return tabs;
    }
  },
  methods: {
    ...mapActions("boardPost", ["getFeeling"]),
    // 해당 감정표현 객체
    feltType(type) {
      return this.feelTypeList.find(item => item.value == type);
    },
    avatar(userName) {
      return userName.slice(0, 1).toUpperCase();
    },
    // 날짜 포맷
    convertDateFromMillis(millis, format = "YYYY.MM.DD (dd) HH:mm") {
      return moment(millis).format(format);
    },
    setIsShow(isShow) {
      this.$emit("onLayoutChange", {
        showFeelList: isShow
      });
    }
  }
};
</script>
