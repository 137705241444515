<template>
  <div class="mt-1">
    <v-chip
      label
      small
      :key="idx"
      :text-color="textColor(tag)"
      :color="color(tag)"
      v-for="(tag, idx) in tags"
      class="mr-1 mb-1 cr-tag-chip"
      :class="dense ? 'dense' : ''"
      @click.stop="searchByTag(tag)"
    >
      {{ tag }}
    </v-chip>
  </div>
</template>

<style lang="scss" scoped>
.cr-tag-chip {
  border-radius: 2px !important;
  padding: 3px 5px 2px 5px;

  &.dense {
    font-size: 11px;
    line-height: 1;
    height: auto;
    opacity: 0.8;
  }
}
</style>

<script>
import { invertColor } from "@/commons/utils/invertColor";
import { mapGetters } from "vuex";
export default {
  props: {
    tags: {
      type: Array,
      description: "태그 목록",
      default: () => []
    },
    dense: {
      type: Boolean,
      description: "",
      default: false
    }
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  computed: {
    ...mapGetters("boardConfig", ["getTags"]),
    color() {
      return tag => {
        const [matchTag] = this.getTags.filter(t => t.tag == tag);
        return matchTag?.color ?? "#E0E0E0";
      };
    },
    textColor() {
      return tag => {
        const color = this.color(tag);
        return invertColor(color);
      };
    }
  },
  methods: {
    searchByTag(tag) {
      this.$router.push({
        name: "board_search",
        params: {
          type: "post",
          query: JSON.stringify({ searchType: "ALL", tags: [tag] })
        }
      });
    }
  }
};
</script>
