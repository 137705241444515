<template>
  <div>
    <!-- 댓글 리스트 -->
    <v-list
      ref="rootList"
      flat
      class="reply_list py-2 px-4"
      :class="replyListClass"
    >
      <!-- 이전 댓글 더보기 버튼 -->
      <v-btn
        text
        color="primary"
        v-if="existPrevReply"
        class="text-caption"
        :disabled="loading"
        :ripple="false"
        @click.stop="loadPrevReply"
      >
        {{ loading ? $t("board.126") : $t("board.91") }}
      </v-btn>
      <v-list-item v-for="reply in reply.list" :key="reply.id" class="px-2">
        <div class="cr-list-container" style="width:100%">
          <!-- 댓글 메인 -->
          <div class="d-flex align-center">
            <Reply
              ref="reply"
              :post="post"
              :reply="reply"
              @showReplyWriter="
                setShowReplyWriter({ replyId: reply.id, command: true })
              "
            />
          </div>
          <!-- 답글(SUB) 리스트 -->
          <div class="pl-12">
            <ReplyList
              ref="subList"
              v-if="existSubReply(reply)"
              :post="post"
              :reply="reply.children"
              :parentId="reply.id"
              @showReplyWriter="
                setShowReplyWriter({ replyId: reply.id, command: true })
              "
            />
            <!-- 답글 입력폼 -->
            <ReplyWriter
              ref="subWriter"
              v-if="showReplyWriter[reply.id]"
              class="reply_writer_wrapper px-3 pb-3"
              :class="existSubReply(reply) ? 'mt-2' : ''"
              :parentId="reply.id"
              v-bind="$props"
              @cancel="
                setShowReplyWriter({ replyId: reply.id, command: false })
              "
              @save="saveSubReply"
            />
          </div>
        </div>
      </v-list-item>
      <!-- 다음 댓글 더보기 버튼 -->
      <v-btn
        text
        color="primary"
        v-if="existNextReply"
        class="text-caption"
        :disabled="loading"
        :ripple="false"
        @click.stop="loadMoreReply"
      >
        {{ loading ? $t("board.126") : "다음 댓글 더보기" }}
      </v-btn>
    </v-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Reply from "./Reply";
import ReplyWriter from "./ReplyWriter";

export default {
  name: "ReplyList",
  components: { Reply, ReplyWriter },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    reply: {
      type: Object,
      default: () => ({
        list: [],
        totalPages: 0,
        page: 0
      }),
      description: "게시물"
    },
    parentId: {
      type: Number,
      default: null,
      description: "답글(sub)일 경우 부모 아이디"
    },
    postType: {
      type: String,
      description: "게시물 타입"
    }
  },
  data() {
    return {
      showReplyWriter: {},
      loading: false
    };
  },
  methods: {
    ...mapActions("boardReply", [
      "loadReplyList",
      "loadSubReplyList",
      "addReply"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    /* 이전 댓글 목록 조회 */
    async loadPrevReply() {
      // 현재 스크롤 높이 기억
      let list;
      let beforeHeight;
      if (this.isRoot) {
        list = this.$refs.rootList.$el;
        beforeHeight = list.scrollHeight;
      }

      this.loading = true;
      if (this.isRoot) {
        await this.loadReplyList({
          postId: this.post.id,
          page: this.reply.page + 1
        });
      } else {
        await this.loadSubReplyList({
          postId: this.post.id,
          parentId: this.parentId,
          page: this.reply.page + 1
        });
      }
      this.loading = false;

      // 목록 조회 완료 후 스크롤 처음으로 이동
      if (this.isRoot) {
        const afterHeight = list.scrollHeight;
        list.scroll({ top: afterHeight - beforeHeight });
        setTimeout(() => {
          list.scroll({ top: 0, behavior: "smooth" });
        }, 200);
      }
    },
    /* 다음 댓글 목록 조회 */
    async loadMoreReply() {
      this.loading = true;
      if (this.isRoot) {
        await this.loadReplyList({
          postId: this.post.id,
          page: this.reply.firstPage - 1
        });
      } else {
        await this.loadSubReplyList({
          postId: this.post.id,
          parentId: this.parentId,
          page: this.reply.firstPage - 1
        });
      }
      this.loading = false;
    },
    /* 답글 존재 여부 */
    existSubReply(reply) {
      const childCount = reply.children?.list?.length ?? 0;
      return childCount > 0;
    },
    /* 답글 입력창 보이기 */
    setShowReplyWriter({ replyId, command }) {
      // SUB 댓글에서 메뉴 클릭시 부모 댓글로 이벤트 넘김
      if (!this.isRoot) {
        this.$emit("showReplyWriter");
        return;
      }
      this.showReplyWriter = {};
      this.$set(this.showReplyWriter, replyId, command);
    },
    /* 답글 등록 */
    async saveSubReply() {
      // 등록 완료 후 입력폼 숨기기
      this.showReplyWriter = {};
    }
  },
  computed: {
    existPrevReply() {
      return this.reply.totalPages > this.reply.page + 1;
    },
    existNextReply() {
      return this.reply?.firstPage > 0;
    },
    isRoot() {
      return !this.parentId;
    },
    replyListClass() {
      let result = this.isRoot ? "ROOT" : "SUB";
      const replyList = this.reply?.list ?? [];
      result = result.concat(replyList.length > 0 ? "" : " cr-reply-empty");
      // 게시물 보기 or 다이얼로그가 아니라면 최대높이 제한
      if (
        this.isRoot &&
        this.$route.name != "board_post_view" &&
        this.postType != "dialog"
      ) {
        result = result.concat(" cr-limit-height");
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr_reply_wrapper {
  // 댓글 리스트
  .reply_list {
    transition: max-height 0.2s ease-out;
    width: 100%;
    &.ROOT {
      border-top: thin solid rgba(0, 0, 0, 0.12);
      position: relative;
      overflow: auto;
      padding: 16px 16px;
      &.cr-limit-height {
        max-height: 500px;
      }
    }
    &.SUB {
      padding: 0px;
      border-radius: 10px;
      background: #f5f5f5;
    }
    &.cr-reply-empty {
      border: none !important;
      max-height: 0px !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  // 댓글 입력창
  .reply_writer_wrapper {
    border: thin solid rgba(0, 0, 0, 0.12);
    .reply_btns {
      padding: 0px 32px 16px;
    }
  }
}
// 하이라이트
.cr-highlight {
  background: #fff9c4;
  width: 100%;
  padding: 8px 16px;
  margin: 4px 0px;
  border-radius: 0.75rem;
}
</style>
