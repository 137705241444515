<template>
  <v-card
    :ripple="false"
    elevation="0"
    outlined
    class="cr-card-main mx-auto"
    :class="
      type == 'view'
        ? 'card-type-view'
        : type == 'dialog'
        ? 'card-type-dialog'
        : ''
    "
    @click="updateUnfold"
  >
    <PostHeader
      v-bind="$props"
      v-on="$listeners"
      :postLayout="postLayout"
      @onLayoutChange="onLayoutChange"
    />
    <PostContent
      v-bind="$props"
      v-on="$listeners"
      :postLayout="postLayout"
      @onLayoutChange="onLayoutChange"
    />
    <PostFooter
      ref="footer"
      v-bind="$props"
      v-on="$listeners"
      :postLayout="postLayout"
      @onLayoutChange="onLayoutChange"
    />
  </v-card>
</template>

<style lang="scss" scoped>
.v-card ::v-deep {
  cursor: default;
}
.cr-card-main {
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  &.card-type-view,
  &.card-type-dialog {
    box-shadow: none !important;
  }
  &.card-type-dialog {
    border: none;
  }
}
.v-card--link:focus::before {
  opacity: 0;
}
</style>

<script>
import PostHeader from "./PostHeader.vue";
import PostContent from "./PostContent.vue";
import PostFooter from "./PostFooter.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { PostHeader, PostContent, PostFooter },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시물 타입"
    }
  },
  data() {
    return {
      postLayout: {
        isUnfold: false,
        showFeelList: false,
        showReplyBox: false,
        showReadListBox: false
      }
    };
  },
  async mounted() {
    // 댓글 지정 다이얼로그라면 댓글창 열기
    if (this.type === "dialog" && this.getParams?.replyId) {
      this.onLayoutChange({ showReplyBox: true });
    }
    // 게시글 보기일 경우 본문 펼치기 && 읽음 처리
    if (this.type === "view" || this.type === "dialog") {
      this.postLayout = { ...this.postLayout, isUnfold: true };
    }
  },
  watch: {
    postLayout(n, p) {
      // 본문 펼침 닫기 액션
      if (n?.isUnfold != p?.isUnfold) {
        if (this.type === "dialog") return;
        this.postLayout.showReplyBox = n.isUnfold;
        this.postLayout.showFeelList = !n.isUnfold
          ? n.isUnfold
          : this.postLayout.showFeelList;
        this.postLayout.showReadListBox = !n.isUnfold
          ? n.isUnfold
          : this.postLayout.showReadListBox;
      }
    }
  },
  methods: {
    ...mapActions("boardPost", ["readPost"]),
    ...mapActions("board", ["loadBoards"]),
    // 카드 펼치기
    updateUnfold(e) {
      e.preventDefault();
      if (this.type === "view" || this.postLayout.isUnfold) return;
      const { id, seen } = this.post;
      this.postLayout = { ...this.postLayout, isUnfold: true };
      // 처음 읽을 경우 읽음처리
      if (!seen) {
        this.readPost(id);
      }
    },
    // 레이아웃 옵션 변경 이벤트
    onLayoutChange(changeOption) {
      this.postLayout = { ...this.postLayout, ...changeOption };
    }
  },
  computed: {
    ...mapGetters("board", ["getBoardById"]),
    ...mapGetters("boardDialog", ["getParams"])
  }
};
</script>
