<template>
  <div class="file_list mt-1">
    <v-list-item v-for="(file, i) in post.files" :key="i" class="px-0" dense>
      <v-list-item-content class="py-0">
        <v-list-item-subtitle>
          <FileItem :file="file" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<style lang="scss" scoped>
.file_list {
  .v-list-item {
    height: 30px;
    min-height: 30px;
    .v-list-item__subtitle {
      > i.file_icon {
        margin-right: 2px;
      }
      .file_name {
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import uploadType from "@/board/constant/uploadType";
import FileItem from "./FileItem.vue";
export default {
  components: { FileItem },
  props: {
    post: {
      type: Object,
      description: "게시물"
    }
  },
  async mounted() {
    await this.getFileList({
      postId: this.post.id,
      uploadType: uploadType.POST
    });
  },
  destroyed() {
    this.RESET_FILE_LIST(this.post.id);
  },
  watch: {
    fileList(n, p) {
      if (n.length === p.length || n.length > 0) return;
      this.$emit("update:showFileList", false);
    }
  },
  methods: {
    ...mapActions("boardPost", ["getFileList"]),
    ...mapMutations("boardPost", ["RESET_FILE_LIST"]),
    // 파일 사이즈 포맷
    byteCalculation(size) {
      return byteCalculation(size);
    },
    /** 상태 값 */
    fileIcons(ext) {
      return fileIcons[ext] || fileIcons["file"];
    },
    fileIconColors(ext) {
      return fileIconColors[ext] || fileIconColors["file"];
    },
    // 파일 확장자
    fileExt(item) {
      const index = item.name.lastIndexOf(".");
      return item.name.substring(index + 1, item.name.length).toLowerCase();
    }
  },
  computed: {
    fileList() {
      return this.post.files;
    }
  }
};
</script>
