<template>
  <div ref="reply" style="width:100%">
    <!-- 삭제된 댓글일 경우 -->
    <div v-if="reply.isBlind" class="py-4 text-body-2 grey--text">
      {{ $t("board.125") }}
    </div>
    <div v-else>
      <v-hover v-slot="{ hover }">
        <div class="d-flex align-start">
          <!-- 프로필 아이콘-->
          <div class="py-1">
            <v-list-item-avatar size="35" class="mr-3">
              <div class="cr-reply-avatar">{{ avatar }}</div>
            </v-list-item-avatar>
          </div>
          <!-- 제목 내용 -->
          <div class="overflow-hidden" style="width:100%">
            <v-list-item-content class="reply_wrapper">
              <!-- 타이틀 -->
              <v-list-item-title
                class="reply_user d-flex align-center mb-1 font-weight-bold"
              >
                <!-- 작성자 -->
                <UserName
                  class="mr-3"
                  :userId="reply.userId"
                  :userName="reply.userName"
                  :userEmail="reply.userEmail"
                />
                <!-- 날짜 -->
                <span class="mr-1 text-caption grey--text text--darken-2">
                  {{ convertDateFromMillis(reply.createdTimeMillis) }}
                </span>
                <!-- 컨텍스트 메뉴 -->
                <span v-if="hover && isControllable && !isEdit && !isDeleted">
                  <v-btn x-small icon @click.stop="openPositioningMenu">
                    <v-icon color="grey" size="16">
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </span>
                <!-- 답글 버튼 -->
                <span v-if="hover && !isEdit && !isDeleted">
                  <v-btn x-small icon @click.stop="$emit('showReplyWriter')">
                    <v-icon dense color="grey" size="16">
                      mdi-arrow-right-bottom
                    </v-icon>
                  </v-btn>
                </span>
              </v-list-item-title>
              <!-- 댓글 본문 영역 -->
              <div v-if="!isEdit" class="overflow-hidden">
                <!-- 내용 -->
                <v-list-item-subtitle
                  class="reply_content"
                  v-html="reply.replyContent"
                />
                <!-- 첨부파일 -->
                <div
                  v-if="reply.attach"
                  class="pa-2 mt-2 rounded attach-wrapper"
                >
                  <FileItem :file="reply.attach" />
                </div>
              </div>
              <v-list-item-subtitle v-else>
                <!--  댓글 수정 박스  -->
                <ReplyWriter
                  class="reply_edit_wrapper"
                  ref="replyWriter"
                  :initContent="reply.replyContent"
                  :initAttach="reply.attach"
                  v-bind="$props"
                  @cancel="isEditReset"
                  @save="editReply"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </div>
      </v-hover>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "@/_locales";
import ReplyWriter from "./ReplyWriter";
import FileItem from "@/board/views/components/main/post/content/file/FileItem.vue";
import UserName from "@/board/views/components/common/item/UserName";

export default {
  name: "Reply",
  components: { ReplyWriter, FileItem, UserName },
  data: () => ({
    isEdit: false
  }),
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    reply: {
      type: Object,
      description: "댓글"
    }
  },
  watch: {},
  mounted() {
    // 댓글 다이얼로그일 경우 하이라이트 처리
    const { replyId } = this.getParams;
    if (this.reply.id === replyId) {
      this.$refs.reply.classList.add("cr-highlight");
      this.$refs.reply.scrollIntoView();
    }
  },
  computed: {
    ...mapGetters("boardDialog", ["getParams"]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("board", ["getBoards", "getBoardById"]),
    isControllable() {
      const groupId = this.getBoardById(this.post.boardId).groupId;

      const [targetBoard = {}] = this.getBoards.filter(
        item => item.id == groupId
      );

      let result = false;
      switch (targetBoard.userPrivilege) {
        case "ADMIN":
          result = true;
          break;
        case "READ_WRITE":
        case "READ":
          result = this.reply.userId == this.getUserInfo.id ? true : false;
          break;
        default:
          break;
      }
      return result;
    },
    avatar() {
      return this.reply.userName.slice(0, 1).toUpperCase();
    },
    isDeleted() {
      const board = this.getBoardById(this.post.boardId);
      const boardDeleted = board?.isDeleted ?? false;
      const boardTrashType = board?.boardType === "TRASH" ?? false;

      return this.post.isDeleted || boardDeleted || boardTrashType;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("boardReply", ["deleteReply"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    // 날짜 포맷
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    // 컨텍스트 메뉴
    openPositioningMenu(e) {
      e.preventDefault();
      this.closePositioningMenu();
      const itemList = [
        {
          // 댓글 수정
          label: i18n.t("board.86"),
          hideItem: this.reply.userId != this.getUserInfo.id,
          func: () => {
            this.$emit("allCloseWriter");
            this.isEdit = true;
          }
        },
        {
          // 댓글 삭제
          label: i18n.t("board.87"),
          func: () => {
            this.confirm({
              headline: "",
              message: i18n.t("board.88"),
              callback: () =>
                this.deleteReply({
                  replyId: this.reply.id,
                  postId: this.reply.postId,
                  parentId: this.reply.parentId
                })
            });
          }
        }
      ];
      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList
      });
    },
    // 수정 취소
    isEditReset() {
      this.confirm({
        headline: "",
        message: i18n.t("board.89"),
        callback: () => {
          this.isEdit = false;
        }
      });
    },
    // 댓글 수정 저장
    async editReply() {
      this.isEdit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-reply-avatar {
  line-height: 35px;
  width: 35px;
  height: 35px;
  background: #90a4ae;
  color: white;
}
.reply_wrapper ::v-deep {
  // 유저 정보 부분
  .reply_user {
    height: 21px;
  }
  // 댓글 내용 부분
  .reply_content {
    user-select: text;
    // 칸이 넘어갔을때 ...표시 방지
    overflow: visible;
    white-space: normal;
    color: rgba(0, 0, 0, 0.7) !important;
  }
  // 수정 시 댓글 작성하는 writer박스
  .reply_edit_wrapper {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 0px 10px 10px;
  }
  // 첨부파일 wrap
  .attach-wrapper {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
  }
}
</style>
