<template>
  <div class="d-flex align-center">
    <v-btn
      plain
      text
      class="px-3"
      :ripple="false"
      @click.stop="
        $emit('onLayoutChange', { showReplyBox: !postLayout.showReplyBox })
      "
    >
      <div class="d-flex align-center">
        <v-icon size="19" class="mr-1">mdi-message-reply-text-outline</v-icon>
        <span class="mr-1 black--text text-button" v-text="$t('board.댓글')" />
        <span class="black--text font-weight-bold text-button">
          {{ count }}
        </span>
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      description: "댓글 개수"
    },
    showReplyBox: {
      type: Boolean,
      default: false
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  }
};
</script>
