<template>
  <v-card-text ref="content" class="pa-0 px-4 px-sm-6">
    <BoardContent v-bind="$props" v-on="$listeners" />
    <!-- 접기 아이콘 -->
    <div
      v-if="type == 'list' && postLayout.isUnfold"
      class="cr_unfold_btn primary"
    >
      <v-btn icon :ripple="false" @click.stop="e => updateFold(e)">
        <v-icon color="white" size="40"> mdi-chevron-up </v-icon>
      </v-btn>
    </div>
  </v-card-text>
</template>

<style lang="scss" scoped>
.v-card__text ::v-deep {
  position: relative;

  .cr_unfold_btn {
    position: absolute;
    bottom: 0px;
    right: -19px;
    border-radius: 4px;
  }
}
</style>

<script>
import BoardContent from "@/board/views/components/main/post/content/BoardContent";

export default {
  components: { BoardContent },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시물 타입"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  methods: {
    // 카드 닫기 이벤트
    updateFold(e) {
      e.preventDefault();
      this.$emit("onLayoutChange", { isUnfold: false });
      // 카드 높이가 현재 window사이즈 보다 클경우 강제 스크롤
      let content = this.$refs.content;
      if (content.clientHeight > window.innerHeight - 64) {
        content.scrollIntoView();
      }
    }
  }
};
</script>
