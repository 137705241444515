<template>
  <v-btn
    class="px-3"
    plain
    text
    :ripple="false"
    @click.stop="
      $emit('onLayoutChange', { showReadListBox: !postLayout.showReadListBox })
    "
  >
    <div class="d-flex align-center">
      <span
        class="mr-1 black--text text-button"
        v-text="$t('board.읽음')"
      ></span>
      <span class="black--text font-weight-bold text-button">
        {{ count }}
      </span>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      description: "읽음 개수"
    },
    showReadListBox: {
      type: Boolean,
      default: false
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  }
};
</script>
