<template>
  <v-list-item>
    <!-- 아바타 -->
    <v-list-item-avatar class="cr-avatar ma-0"
      >{{ avatar }}
    </v-list-item-avatar>
    <!-- 이름 -->
    <div class="d-flex align-center ml-4 mr-auto">
      <UserName v-bind="$props" v-on="$listeners" class="text-subtitle-1" />
    </div>
    <!-- 시간 -->
    <span v-if="this.timeMillis > 0" class="text-caption">
      {{ convertedDateFromMillis }}
    </span>
  </v-list-item>
</template>

<style lang="scss" scoped>
.cr-avatar {
  line-height: 35px;
  width: 35px;
  height: 35px;
  background: #90a4ae;
  color: white;
}
</style>
<script>
import moment from "moment";
import UserName from "@/board/views/components/common/item/UserName";

export default {
  components: { UserName },
  props: {
    userName: {
      type: String,
      description: "사용자 이름",
      default: ""
    },
    userEmail: {
      type: String,
      description: "사용자 이메일",
      default: ""
    },
    userId: {
      type: Number,
      description: "사용자 아이디",
      default: 0
    },
    timeMillis: {
      type: Number,
      description: "우측 표시할 시간",
      default: 0
    }
  },
  data: () => ({
    dateFormat: "YYYY.MM.DD (dd) HH:mm"
  }),
  watch: {},
  mounted() {},
  computed: {
    // 날짜 포맷
    convertedDateFromMillis() {
      return moment(this.timeMillis).format(this.dateFormat);
    },
    avatar() {
      return this.userName.slice(0, 1).toUpperCase();
    }
  },
  methods: {}
};
</script>
