<template>
  <div>
    <v-textarea
      solo
      flat
      hide-details
      rows="1"
      auto-grow
      class="reply"
      :label="$t('board.90')"
      ref="replyContent"
      v-model="replyContent"
    ></v-textarea>
    <div class="reply_btns d-flex align-center">
      <!-- 댓글 옵션 -->
      <div
        class="d-flex align-center overflow-hidden pr-1"
        style="margin-left:-4px; width:100%"
      >
        <!-- 첨부파일 버튼 -->
        <v-btn icon small @click="openFileInput">
          <v-icon size="20">
            mdi-paperclip
          </v-icon>
        </v-btn>
        <!-- 첨부파일 Input -->
        <input
          v-show="false"
          type="file"
          ref="fileInput"
          @change="handleFile"
        />
        <!-- 첨부파일 detail -->
        <div
          v-if="file"
          class="d-flex flex-column overflow-hidden"
          style="width:100%"
        >
          <!-- 파일명 -->
          <div class="flex-row d-flex align-center">
            <span v-text="fileName" class="cr-ellipsis text-body-2"></span>
            <span v-text="extension" class="text-body-2"></span>
            <strong v-if="uploading" class="pl-4 ml-auto">
              {{ Math.ceil(progress) }}%
            </strong>
            <v-btn v-else icon x-small class="ml-1" @click.stop="removeFile">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <!-- 프로그레스바 -->
          <div class="flex-row">
            <v-progress-linear
              v-if="uploading"
              rounded
              :value="progress"
              height="2"
            />
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        text
        class="mr-2"
        :disabled="isRoot && !isEdit && !content"
        @click="cancel"
        v-text="$t('common.취소')"
      />
      <v-btn
        depressed
        color="primary"
        @click="onSave"
        :disabled="uploading"
        v-text="$t('board.작성')"
      />
    </div>
  </div>
</template>

<script>
import { addReply, updateReply } from "@/board/api/board.api";
import { isBlank } from "@/commons/utils/validation";
import i18n from "@/_locales";
import axios from "axios";
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    initContent: {
      default: "",
      type: String,
      description: "수정시 기존값"
    },
    initAttach: {
      default: null,
      type: Object,
      description: "수정시 기존 첨부파일"
    },
    parentId: {
      default: 0,
      type: Number,
      description: "답글일 경우 부모 아이디"
    },
    post: {
      type: Object,
      description: "게시물"
    },
    reply: {
      type: Object,
      description: "수정일 경우에만 유효"
    }
  },
  data: () => ({
    replyContent: "",
    attach: null,
    // 기존 파일 삭제여부 (수정일 경우)
    attachRemoved: false,
    uploading: false,
    progress: 0,
    cancelTokenSource: null
  }),
  mounted() {
    this.replyContent = this.initContent.split("<br>").join("\n");
    // 답글 입력창일 경우 focus
    if (!this.isRoot) {
      this.$refs.replyContent.focus();
    }
  },
  beforeDestroy() {
    if (this.uploading && this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("boardReply", ["SET_REPLY", "UPDATE_REPLY"]),
    ...mapMutations("boardPost", ["SET_REPLY_COUNT"]),
    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    handleFile(e) {
      if (e.target.files.length > 0) return (this.attach = e.target.files[0]);
      return (this.attach = null);
    },
    cancel() {
      if (this.uploading) return this.cancelTokenSource.cancel("cancel");
      if (!this.isEdit) this.resetField();
      this.$emit("cancel");
    },
    removeFile() {
      if (!this.attach) {
        this.attachRemoved = true;
      }
      this.$refs.fileInput.value = null;
      this.attach = null;
    },
    resetField() {
      this.replyContent = "";
      this.$refs.fileInput.value = null;
      this.attach = null;
      this.progress = 0;
    },
    // 댓글 등록/수정
    async onSave() {
      this.uploading = true;
      this.cancelTokenSource = axios.CancelToken.source();

      // 본문 내용 없을경우
      if (isBlank(this.content)) {
        this.$refs.replyContent.focus();
        this.uploading = false;
        return this.openSnackbar({
          message: i18n.t("board.90")
        });
      }
      // 등록할 댓글 전송양식
      const formData = new FormData();
      formData.append("postId", this.post.id);
      formData.append("parentId", this.isRoot ? 0 : this.parentId);
      formData.append("replyContent", this.content);
      if (this.attach != null) {
        formData.append("replyAttach", this.attach);
      }
      // 수정일 경우
      if (this.isEdit) {
        formData.append("id", this.reply.id);
        formData.append("attachRemoved", this.attachRemoved);
      }
      // 파일 첨부시 axios confing 세팅
      const config = {
        cancelToken: this.cancelTokenSource.token,
        onUploadProgress: e => {
          // 업로드 progress 퍼센트
          this.progress =
            e.loaded / e.total >= 1 ? 100 : (e.loaded / e.total) * 100;
        }
      };

      const { status, message, data } = this.isEdit
        ? await updateReply(formData, config)
        : await addReply(formData, config);

      if (status !== 201 && status !== 200) {
        // 업로드 실패시
        const type = "ERROR";
        const msg =
          message === "cancel"
            ? i18n.t("board.127")
            : status === 507
            ? i18n.t("board.128")
            : i18n.t("board.28");
        this.openSnackbar({ type, message: msg });

        this.progress = 0;
      } else {
        // 업로드 성공시
        this.resetField();
        // store에 최신화
        if (this.isEdit) {
          await this.UPDATE_REPLY(data);
        } else {
          await this.SET_REPLY(data);
          this.SET_REPLY_COUNT({
            postId: this.post.id,
            count: this.post.replyCount + 1
          });
        }
        // 등록/수정 성공시 후속 이벤트 전파
        this.$emit("save");
      }

      this.uploading = false;
      this.cancelTokenSource = null;
    }
  },
  computed: {
    // 줄바꿈 표시를 표현하기 위한 코드
    content() {
      return this.replyContent.split("\n").join("<br>");
    },
    // ROOT 댓글인지 여부
    isRoot() {
      return !(this.parentId > 0);
    },
    isEdit() {
      return this.initContent;
    },
    file() {
      // props.initAttach | data.attach 간의 스코프 변경 조건
      if (this.initAttach && !this.attachRemoved) return this.initAttach;
      return this.attach;
    },
    fileName() {
      const fullName = this.file?.name ?? "";
      let str = fullName.split(".");
      let result = "";
      for (let i = 0; i < str.length - 1; i++) {
        result += i > 0 ? "." + str[i] : str[i];
      }
      return result;
    },
    extension() {
      const fullName = this.file?.name ?? "";
      let str = fullName.split(".");
      return "." + str[str.length - 1];
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  background: #fff;
  .v-input.v-textarea ::v-deep {
    .v-input__slot {
      padding: 0px !important;
      textarea {
        margin-bottom: 10px;
      }
    }
  }
  .cr-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
