<template>
  <v-menu
    top
    right
    offset-y
    close-on-click
    class="mr-3"
    :disabled="post.ifelt"
    content-class="cr-feel-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center mr-4">
        <div
          class="d-flex align-center cr-icon-wrap"
          @mouseover="showDescription = true"
          @mouseleave="showDescription = false"
        >
          <!-- 따봉 아이콘 -->
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            :disabled="isDeleted"
            :color="post.ifelt ? 'primary' : 'grey'"
            @click.stop="initBtnClick"
          >
            <v-icon size="18">
              {{ post.ifelt ? "mdi-thumb-up" : "mdi-thumb-up-outline" }}
            </v-icon>
          </v-btn>
          <!-- 감정표현 개수 -->
          <div
            v-if="post.feelCount > 0"
            style="cursor: pointer;"
            class="text-caption pr-2"
            @click.stop="
              $emit('onLayoutChange', {
                showFeelList: !postLayout.showFeelList
              })
            "
          >
            <span class="font-weight-bold">{{ post.feelCount }}</span>
          </div>
        </div>
        <!-- 내가 표현한 감정표현 -->
        <div
          v-if="showFeltType"
          class="d-flex align-center text-body-2 grey--text"
        >
          {{ $t("board.157", { value: $t(`board.${feltType.title}`) }) }}
        </div>
      </div>
    </template>

    <!-- 메뉴 - 감정표현 아이콘 목록 -->
    <v-card class="pa-2">
      <FeelingItem
        v-for="item in feelingItemList"
        :key="item.value"
        :post="post"
        :item="item"
      />
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped>
.v-tooltip__content {
  padding: 4px 8px;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
.cr-feel-menu {
  border-radius: 32px;
  margin-left: -16px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import FeelingItem from "./FeelingItem.vue";
import { feelTypeList } from "@/board/constant/boardConstraint.js";

export default {
  components: { FeelingItem },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    postLayout: {
      type: Object,
      default: () => {},
      description: "레이아웃 옵션"
    }
  },
  data() {
    return {
      showDescription: false,
      feelingItemList: feelTypeList
    };
  },
  methods: {
    ...mapActions("boardPost", ["deleteFeeling"]),
    // 감정표현 취소
    initBtnClick() {
      const { id, ifelt } = this.post;
      if (!ifelt) return;
      this.deleteFeeling(id);
    }
  },
  computed: {
    ...mapGetters("board", ["getBoardById"]),
    feltType() {
      return this.feelingItemList.find(
        item => item.value == this.post.feelType
      );
    },
    showFeltType() {
      return this.showDescription && this.post.ifelt && this.post.feelType;
    },
    isDeleted() {
      const board = this.getBoardById(this.post.boardId);
      const boardDeleted = board?.isDeleted ?? false;
      const boardTrashType = board?.boardType === "TRASH" ?? false;
      return this.post.isDeleted || boardDeleted || boardTrashType;
    }
  }
};
</script>
