<template>
  <div class="text-subtitle-2 d-flex align-center">
    <v-icon class="file_icon" :size="23" :color="fileIconColors(fileExt(file))">
      {{ fileIcons(fileExt(file)) }}
    </v-icon>
    <v-hover v-slot="{ hover }">
      <div
        class="file_name mr-1 text-truncate"
        :class="hover ? 'text-decoration-underline' : ''"
        @click.stop="downLoadFile(file.id)"
      >
        {{ file.name }}
      </div>
    </v-hover>
    <span class="font-weight-regular grey--text">
      ({{ byteCalculation(file.size) }})
    </span>
  </div>
</template>

<style lang="scss" scoped>
.text-subtitle-2 {
  white-space: nowrap;
  i.file_icon {
    margin-right: 4px;
  }
  .file_name {
    cursor: pointer;
  }
}
</style>

<script>
import storage from "@/commons/api/storage";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
export default {
  props: {
    file: {
      type: Object,
      description: "첨부파일"
    }
  },
  async mounted() {},
  destroyed() {},
  watch: {},
  methods: {
    // 파일 사이즈 포맷
    byteCalculation(size) {
      return byteCalculation(size);
    },
    /** 상태 값 */
    fileIcons(ext) {
      return fileIcons[ext] || fileIcons["file"];
    },
    fileIconColors(ext) {
      return fileIconColors[ext] || fileIconColors["file"];
    },
    // 파일 확장자
    fileExt(item) {
      const index = item.name.lastIndexOf(".");
      return item.name.substring(index + 1, item.name.length).toLowerCase();
    },
    // 파일 다운로드
    downLoadFile(fileId) {
      const token = storage.getAccessToken();
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/board/file/download/${fileId}?&access_token=${token}`
      );
    }
  },
  computed: {}
};
</script>
