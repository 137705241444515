<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="menu">
        <v-tooltip top>
          <template v-slot:activator="tooltip">
            <div
              v-bind="{ ...menu.attrs, ...tooltip.attrs }"
              v-on="{ ...menu.on, ...tooltip.on }"
              v-html="userName"
              class="cr-user-name"
            ></div>
          </template>
          <!-- tooltip 메시지 -->
          <span>{{ rawUserEmail }}</span>
        </v-tooltip>
      </template>
      <!-- 이름 클릭시 메뉴 -->
      <v-list outlined dense tile>
        <v-hover v-slot="{ hover }" v-for="(item, index) in items" :key="index">
          <v-list-item
            :class="hover ? 'cr-list-item--hover' : ''"
            :disabled="item.disabled"
            @click="item.click"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-hover>
      </v-list>
    </v-menu>
  </div>
</template>
<style lang="scss" scoped>
.cr-user-name {
  &:hover {
    text-decoration: underline;
  }
}
.cr-list-item--hover {
  background-color: #c5e0ff71;
  cursor: pointer;
}
</style>
<script>
import { mapActions } from "vuex";
import i18n from "@/_locales";
export default {
  props: {
    userName: {
      type: String,
      description: "사용자 이름",
      default: ""
    },
    userEmail: {
      type: String,
      description: "사용자 이메일",
      default: ""
    },
    userId: {
      type: Number,
      description: "사용자 아이디",
      default: 0
    }
  },
  data() {
    return {
      items: [
        {
          // 메일 쓰기
          title: i18n.t("board.166"),
          click: () => {
            this.routeMailWrite({
              openPopup: true,
              to: [this.fullEmail]
            });
          }
        },
        {
          // 작성한 게시글 검색
          title: i18n.t("board.167"),
          click: () => {
            this.$router.push({
              name: "board_search",
              params: {
                type: "post",
                query: JSON.stringify({
                  searchType: "WRITER",
                  keyword: this.rawUserEmail
                })
              }
            });
          }
        },
        {
          // 작성한 댓글 검색
          title: i18n.t("board.168"),
          click: () => {
            this.$router.push({
              name: "board_search",
              params: {
                type: "reply",
                query: JSON.stringify({
                  searchType: "WRITER",
                  keyword: this.rawUserEmail
                })
              }
            });
          }
        },
        {
          // 업로드한 파일 검색
          title: i18n.t("board.169"),
          click: () => {
            this.$router.push({
              name: "board_search",
              params: {
                type: "file",
                query: JSON.stringify({
                  searchType: "UPLOADER",
                  keyword: this.rawUserEmail
                })
              }
            });
          }
        }
      ]
    };
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("mailRoute", ["routeMailWrite"])
  },
  computed: {
    // 이름 정보 존재시 "이름"<이메일> 포맷팅
    fullEmail() {
      return `${this.rawUserName ? `"${this.rawUserName}" ` : ""}${
        this.rawUserName ? `<${this.rawUserEmail}>` : this.rawUserEmail
      }`;
    },
    // 하이라이트 태그 제거
    rawUserName() {
      let dom = document.createElement("div");
      dom.innerHTML = this.userName;
      return dom.innerText;
    },
    // 하이라이트 태그 제거
    rawUserEmail() {
      let dom = document.createElement("div");
      dom.innerHTML = this.userEmail;
      return dom.innerText;
    }
  }
};
</script>
