<template>
  <div
    ref="replyBox"
    class="cr_reply_wrapper pa-0"
    :class="false ? 'cr-reply-empty' : ''"
  >
    <ReplyList
      ref="rootListWrapper"
      v-if="true"
      :type="'ROOT'"
      :postType="type"
      v-bind="$props"
      :reply="reply"
    />
    <ReplyWriter
      v-if="!isDeleted"
      class="reply_writer_wrapper px-4 px-sm-6 pt-2 pb-4"
      ref="replyWriter"
      v-bind="$props"
      @save="saveReply"
    />
  </div>
</template>

<style lang="scss" scoped>
// 댓글 영역
.cr_reply_wrapper {
  // 댓글 리스트
  .reply_list {
    &.ROOT {
      position: relative;
      max-height: 600px;
      overflow: auto;
    }
  }

  &.cr-reply-empty {
    border: none;
    .reply_list {
      display: none;
    }
  }

  // 댓글 입력창
  .reply_writer_wrapper {
    border-top: thin solid rgba(0, 0, 0, 0.12);
    .reply_btns {
      padding: 0px 32px 16px;
    }
  }
}
</style>

<script>
import ReplyList from "@/board/views/components/main/post/footer/reply/ReplyList";
import ReplyWriter from "./ReplyWriter";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { ReplyList, ReplyWriter },
  props: {
    post: {
      type: Object,
      description: "게시물"
    },
    type: {
      type: String,
      description: "게시판 유형"
    }
  },
  data() {
    return {};
  },
  watch: {
    reply(n, p) {
      // 다이얼로그에서 댓글창 열릴시 스크롤 위치 이동
      if (this.type === "dialog" && !p) {
        setTimeout(() => {
          this.$refs.replyBox.scrollIntoView({ behavior: "smooth" });
        }, 10);
      }
    }
  },
  async mounted() {
    // 댓글 지정 다이얼로그라면 값초기화 X
    if (this.type === "dialog" && this.getParams?.replyId) return;
    // 펼침시 댓글 초기화 및 첫페이지 로드
    await this.RESET_REPLY(this.post.id);
    this.loadReplyList({ postId: this.post.id });
  },
  destroyed() {
    // 댓글 데이타 초기화
    if (this.type !== "dialog") this.RESET_REPLY(this.post.id);
  },
  computed: {
    ...mapGetters("boardReply", ["getReplyList"]),
    ...mapGetters("boardDialog", ["getParams"]),
    ...mapGetters("board", ["getBoardById"]),
    isDeleted() {
      const board = this.getBoardById(this.post.boardId);
      const boardDeleted = board?.isDeleted ?? false;
      const boardTrashType = board?.boardType === "TRASH" ?? false;

      return this.post.isDeleted || boardDeleted || boardTrashType;
    },
    reply() {
      return this.getReplyList(this.post.id);
    }
  },
  methods: {
    ...mapActions("boardReply", ["loadReplyList", "loadReplyListById"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("boardReply", ["RESET_REPLY"]),
    // 댓글 등록
    async saveReply() {
      // 댓글 스크롤 맨 아래로 이동
      const list = this.$refs?.rootListWrapper?.$refs?.rootList?.$el ?? null;
      if (list) list.scroll({ top: list.scrollHeight, behavior: "smooth" });
    }
  }
};
</script>
