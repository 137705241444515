<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        large
        icon
        v-on="on"
        v-bind="attrs"
        @click="saveFeeling({ postId: post.id, feelType: item.value })"
      >
        <div class="cr-feel-icon" v-html="item.svg"></div>
      </v-btn>
    </template>
    <span class="text-caption" v-text="$t(`board.${item.title}`)"></span>
  </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
      description: "게시물"
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("boardPost", ["saveFeeling"])
  }
};
</script>

<style lang="scss" scoped>
.cr-feel-icon {
  width: 32px;
  height: 32px;
  margin-bottom: 2px;
}
</style>
