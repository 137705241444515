<template>
  <v-card :ripple="false" elevation="0" outlined class="cr-card-main px-6 py-2">
    <!-- 헤더 -->
    <div class="pb-5 px-0 pt-3 cr-user-info">
      <div class="d-flex align-center">
        <!-- 프로필 아이콘-->
        <div>
          <div class="cr-avatar mr-2"></div>
        </div>
        <!-- 작성자 게시판 정보 -->
        <div class="d-flex flex-column justify-center">
          <div class="d-flex align-center pb-1">
            <!-- 작성자 -->
            <span class="user-info cr-block" style="width:50px;"></span>
            <v-icon class="mx-1" size="18">
              mdi-chevron-right
            </v-icon>
            <!-- 게시판 이름 -->
            <span class="mr-1 cr-block" style="width:50px;"></span>
          </div>
          <!-- 날짜 -->
          <span class="mr-1 cr-block" style="line-height: 1rem; "> </span>
        </div>
      </div>
    </div>

    <!-- 본문 -->
    <div class="cr-board-content">
      <!-- 제목 -->
      <div class="cr-block mb-2" style="max-width:50%"></div>
      <!-- 본문 -->
      <div class="py-2">
        <div class="cr-block mb-1"></div>
        <div class="cr-block mb-1"></div>
        <div class="cr-block mb-1"></div>
        <div class="cr-block mb-1"></div>
      </div>
    </div>

    <!-- 하단버튼 -->
    <div class="d-flex py-4">
      <!-- 따봉 -->
      <div class="cr-block" style="height:19px; width:30px"></div>
      <v-spacer></v-spacer>
      <!-- 댓글 -->
      <div class="cr-block" style="height:19px; width:50px"></div>
      <v-divider vertical class="mx-3" style="height: 19px"></v-divider>
      <!-- 읽음 -->
      <div class="cr-block" style="height:19px; width:50px"></div>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-card-main {
  @keyframes load {
    100% {
      background-position: -100% 0;
    }
  }
  .cr-block {
    display: block;
    border-radius: 4px;
    min-width: 20px;
    min-height: 19px;
    height: 100%;
    background: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 1s infinite;
  }
  .cr-user-info {
    .cr-avatar {
      text-align: center;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
      );
      background-size: 200% 100%;
      background-position: 100% 0;
      animation: load 1s infinite;
    }
    .board-info {
      font-weight: 400;
    }
  }
}
</style>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  async mounted() {},
  watch: {},
  methods: {},
  computed: {}
};
</script>
